//BlogPostAPI.js
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { BLOG_API } from "../Config";

// Query to fetch a single blog post by ID
const GET_SINGLE_POST = gql`
  query GetAllPublishedPosts($where: PostWhereUniqueInput!) {
    post(where: $where) {
      id
      author {
        name
      }
      publishDate
      title
      content {
        document
      }
    }
  }
`;

// Query to get all published blog post titles and their authors
const GET_ALL_PUBLISHED_POSTS = gql`
  query GetAllPublishedPosts($where: PostWhereInput!) {
    posts(where: $where) {
      id
      title
      author {
        name
      }
      description
      tags {
        name
      }
      publishDate
    }
  }
`;

class BlogPostAPI {
  constructor() {
    this.client = new ApolloClient({
      uri: BLOG_API,
      cache: new InMemoryCache(),
    });
  }

  // Function to fetch a single blog post by ID
  async getSinglePost(postId) {
    try {
      const result = await this.client.query({
        query: GET_SINGLE_POST,
        variables: {
          where: {
            id: postId,
          },
        },
      });
      return result.data.post;
    } catch (error) {
      console.error("Error fetching single blog post:", error);
      throw error;
    }
  }

  // Function to get all published blog post titles and their authors
  async getAllPublishedPosts() {
    try {
      const result = await this.client.query({
        query: GET_ALL_PUBLISHED_POSTS,
        variables: { where: { isPublished: { equals: true } } },
      });
      return result.data.posts;
    } catch (error) {
      console.error("Error fetching published blog posts:", error);
      throw error;
    }
  }

  async getMostRecentPost() {
    try {
      const resultArray = await this.getAllPublishedPosts();
      console.log(resultArray);
      if (resultArray) {
        console.log("Most recent post:", resultArray[0]);
        return await this.getSinglePost(resultArray[0].id);
      } else {
        console.log("No published blog posts found.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching most recent blog post:", error);
      throw error;
    }
  }
}

export default BlogPostAPI;
