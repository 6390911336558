import React from 'react';
import { Route, Routes } from 'react-router-dom'; // Update this
import HomePage from "./pages/HomePage";
// Import other pages if they exist
// import GetStarted from "./pages/GetStarted";
// import LearnMore from "./pages/LearnMore";
import { GET_STARTED, LEARN_MORE, HOME, PRICING, BLOG, CONTACT_US, FUNDING, CONSULTING, PRODUCT, ABOUT_US } from './PageRouting'; // Import route constants
import PricingPage from './pages/PricingPage';
import BlogHomePage from './pages/BlogHomePage';
import NotFoundPage from './pages/NotFoundPage';
import ContactUsPage from './pages/ContactUsPage';
import AboutUsPage from './pages/AboutUsPage';
import ComingSoon from './pages/ComingSoon';
import BlogPostPage from './pages/BlogPostPage';
import ThankYouPage from './pages/ThankYouPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={HOME} element={<HomePage />} />
        {/* Onboarding */}
        <Route path={GET_STARTED} element={<ComingSoon />} />

        {/* Marketing */}
        <Route path={PRICING} element={<PricingPage />} />
        <Route path={PRODUCT} element={<PricingPage />} />

        {/* Content Routes */}
        <Route path={ABOUT_US} element={<AboutUsPage />} />
        <Route path={BLOG} element={<BlogHomePage />} />
        <Route path={BLOG + "/:id"} element={<BlogPostPage />} />
        {/* Contact related routes */}
        <Route path={CONTACT_US} element={<ContactUsPage />} />
        <Route path={FUNDING} element={<ContactUsPage />} />
        <Route path={CONSULTING} element={<ContactUsPage />} />
        <Route path={LEARN_MORE} element={<PricingPage />} />
        
        {/* Thank You Page */}
        <Route path="/thank-you" element={<ThankYouPage />} />

        {/* 404 */}
        <Route path="*" element={<NotFoundPage />} />        
      </Routes>
    </div>
  );
}

export default App;
