//main page
export const HOME = "/"

//Blog Pages
export const BLOG = "/blog"

//application
export const LOGIN = "/login"

//information pages
export const GET_STARTED = "/get-started";
export const LEARN_MORE = "/learn-more";
export const ABOUT_US = "/about-us"

//product and pricing
export const PRODUCT = "/product"
export const PRICING = "/pricing"

//consulting
export const CONSULTING = "/consulting"

//legal
export const PRIVACY = "/privacy"
export const TERMS = "/terms"

//support
export const CONTACT_US = "/contact-us"

//funding
export const FUNDING = "/funding"

//thank you
export const THANK_YOU = "/thank-you"