import Footer from "../components/Footer";
import Header from "../components/Header";
import NotifyMeCard from "../components/cta/NotifyMeCard";


export default function ComingSoon() {
    return (
      <div>
        <Header />
        <NotifyMeCard />
        <Footer />
      </div>
    );
  }