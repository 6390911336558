//Pricing Page

import React, { useState } from "react";
import PricingTiers from "../components/pricing/PricingTiers";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PricingDetails from "../components/pricing/PricingDetails";
import FAQ from "../components/information/FAQ";

export default function PricingPage() {
  const frequencies = [
    { value: "monthly",
      label: "Monthly", 
      priceSuffix: "/month"
    },
    {
      value: "annually",
      label: "Annually",
      priceSuffix: "/month",
      discount: true,
    },
  ];
  const tiers = [
    {
      name: "Individuals",
      id: "tier-freelancer",
      href: "#",
      price: "Free",
      description: "Free for individuals.",
      features: [
        "Up to 20 receipts scans per month",
        "Stats dashboard",
        "Fully searchable receipts",
        "Export to CSV",
      ],
      mostPopular: false,
      cta: "Join Now",
    },
    {
      name: "Small Business",
      id: "tier-small-business",
      href: "#",
      price: { monthly: 18, annually: 12 },
      description: "A plan that scales with your rapidly growing business.",
      features: [
        "Everything in Individuals",
        "Up to 1000 receipt scans per month",
        "Export receipts to ZIP",
        "Cloud sync and backup",
        "Email support"
      ],
      mostPopular: true,
      cta: "Buy plan",
    },
    {
      name: "Enterprise",
      id: "tier-enterprise",
      href: "#",
      price: "Custom",
      description: "Dedicated support and infrastructure for your company.",
      features: [
        "Everything in Small Business",
        "Receipt scans limit to suit your needs",
        "Phone support",
        "Feature requests"
      ],
      mostPopular: false,
      cta: "Contact Sales",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const sections = [
    {
      name: 'Receipt Scanning',
      features: [
        { name: 'Up to 20 receipt scans per month', tiers: { Individuals: true, 'Small Business': true, Enterprise: true  } },
        { name: 'Up to 1000 receipt scans per month', tiers: { 'Small Business': true, Enterprise: true } },
        { name: 'Custom pricing per receipt', tiers: { Enterprise: true } },
      ],
    },
    {
      name: 'Data Management',
      features: [
        { name: 'Stats dashboard', tiers: { Individuals: true, 'Small Business': true, Enterprise: true } },
        { name: 'Fully searchable receipts', tiers: { Individuals: true, 'Small Business': true, Enterprise: true } },
        { name: 'Export to CSV', tiers: { Individuals: true, 'Small Business': true, Enterprise: true } },
        { name: 'Export receipts to ZIP', tiers: { 'Small Business': true, Enterprise: true } },
        { name: 'Cloud sync and backup', tiers: { 'Small Business': true, Enterprise: true } },
      ],
    },
    {
      name: 'Support',
      features: [
        { name: 'Email support', tiers: { 'Small Business': true, Enterprise: true } },
        { name: 'Phone support', tiers: { Enterprise: true } },
        { name: 'Feature requests', tiers: { Enterprise: true } },
      ],
    },
  ];
  
  

  const [frequency, setFrequency] = useState(frequencies[1]);

  return (
    <div>
      <Header />
      <PricingTiers frequencies={frequencies} tiers={tiers} classNames={classNames} frequency={frequency} setFrequency={(frequency) => setFrequency(frequency)} />
      <PricingDetails frequencies={frequencies} tiers={tiers} sections={sections} classNames={classNames} frequency={frequency} setFrequency={setFrequency}/>
      <FAQ />
      <Footer />
    </div>
  );
}
