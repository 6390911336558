import React from "react";
import {
  DocumentRenderer,
  DocumentRendererProps,
} from "@keystone-6/document-renderer";

const renderer = {
  // use your editor's autocomplete to see what other renderers you can override
  inline: {
    bold: ({ children }) => {
      return <strong>{children}</strong>;
    },
  },
  block: {
    heading: ({ index, children, level }) => {
      if (level === 1) {
        return (
          <h1
            key={index}
            className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
          >
            {children}
          </h1>
        );
      } else if (level === 2) {
        return (
          <h2
            key={index}
            className={`mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl`}
          >
            {children}
          </h2>
        );
      }
      return (
        <h3
          key={index}
          className={`mt-6 text-lg font-bold tracking-tight text-gray-900 sm:text-2xl`}
        >
          {children}
        </h3>
      );
    },
    //unordered list
    list: ({ type, children, index }) => {
      return (
        <ul key={index} className="mt-6 list-disc list-inside">
          {children.map((child, childIndex) => (
            <li key={childIndex}>{child}</li>
          ))}
        </ul>
      );
    },

    paragraph: ({ index, children, textAlign }) => {
      return (
        <p key={index} className="mt-6">
          {children}
        </p>
      );
    },

    ul: ({ children, index }) => {
      return (
        <ul key={index} className="mt-6 list-disc list-inside">
          {children}
        </ul>
      );
    },

    code: ({ item, index }) => {
      return (
        <pre key={index} className="mt-6 bg-gray-100 p-4">
          <code>{item.children[0].text}</code>
        </pre>
      );
    },
  },
};


function BlogPostRender({ post }) {
  console.log("BlogPostRender post:");
  console.log(post);
  return (
    <div className="bg-white px-6 mx-4 py-8 lg:px-8 lg:py-32">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <DocumentRenderer
          document={post.content.document}
          renderers={renderer}
        />
      </div>
    </div>
  );
}

export default BlogPostRender;
