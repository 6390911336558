import { Link } from "react-router-dom";
import { GET_STARTED, LEARN_MORE } from "../../PageRouting";

export default function CTAGreenBanner() {
    return (
      <div className="bg-blue-800">
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-teal-300 sm:text-4xl">
              Save Time and Money. Join now for free!
            </h2>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to={GET_STARTED}
                className="rounded-md bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Get started
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }