import { useNavigate } from 'react-router-dom';
import { THANK_YOU } from '../../PageRouting';
import {db} from '../../firebase';
import { collection, addDoc } from "firebase/firestore";

export default function NotifyMeCard() {
    const navigate = useNavigate();

    const triggerCustomerSignUp = async (event) => {
      event.preventDefault();
      //post to firebase cloud store
      const email = event.target.email.value
      const data = {
        email: email
      }

      try {
        const docRef = await addDoc(collection(db, "beta-signup"), data);
        console.log("Document written with ID: ", docRef.id);
      }
      catch (e) {
        console.error("Error adding document: ", e);
      }
      
      //navigate to thank you page
      navigate(THANK_YOU);
    }
      

    return (
      <div className="bg-white py-16 sm:py-24">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden bg-teal-300 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
            <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-blue-500-dark sm:text-4xl">
              SpendSage will be launching Early March!
            </h2>
            <p className="mx-auto mt-4 max-w-xl text-center text-lg leading-8 text-black">
              Join the email list to be the first to know when we launch!
            </p>
            <form className="mx-auto mt-10 flex max-w-xl gap-x-4" onSubmit={triggerCustomerSignUp}>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Keep me in the loop!
              </button>
            </form>
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
              aria-hidden="true"
            >
              <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
              <defs>
                <radialGradient
                  id="759c1415-0410-454c-8f7c-9a820de03641"
                  cx={0}
                  cy={0}
                  r={1}
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(512 512) rotate(90) scale(512)"
                >
                  <stop stopColor="#0067B3" />
                  <stop offset={1} stopColor="#00528e" stopOpacity={0} />
                </radialGradient>
              </defs>
            </svg>            
          </div>
        </div>
      </div>
    )
  }