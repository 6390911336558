//Thank you page

import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { HOME } from '../PageRouting';

export default function ThankYouPage() {
    return (
        <div>
            <Header />
            <div className="bg-white">
                <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase">Thank you!</h2>
                        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">We'll be in touch.</p>
                        <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">We'll be sending you an email soon with more information about SpendSage.</p>
                        <div className="mt-10">
                            <Link to={HOME} className="text-base font-medium text-blue-600 hover:text-blue-500">Go back home<span aria-hidden="true"> &rarr;</span></Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
