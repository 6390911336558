import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import BlogPostRender from "../components/blog/BlogPostRender";
import BlogPostAPI from "../apis/BlogAPI";
import { Shimmer } from "react-shimmer";

function SkeletonLoader() {
    return (
      <div className="bg-white px-6 py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <div className="mb-8">
            <div className="flex justify-center">
              <Shimmer width={800} height={60} />
            </div>
          </div>
          <div className="mb-8">
            <div className="flex justify-center">
              <Shimmer width={300} height={30} />
            </div>
          </div>
          {/* paragraphs */}
          <div className="mt-16"/>
          {Array.from({ length: 16 }, (_, index) => (
          <div key={index} className="mb-4">
            <div className="flex justify-center">
              <Shimmer width={800} height={24} />
            </div>
          </div>
        ))}
        </div>
      </div>
    );
  }
  

export default function BlogPostPage() {
  //get the post id from the url
  const url = window.location.href;
  const id = url.substring(url.lastIndexOf("/") + 1);

  const [post, setPost] = useState(null); //the most recent post to be displayed
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    const blogPostAPI = new BlogPostAPI();

    blogPostAPI
      .getSinglePost(id)
      .then((post) => {
        if (post) {
          setPost(post);
        } else {
          console.log("No posts found.");
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div>
      <Header />
      {isLoading ? ( // Render the SkeletonLoader while loading
        <SkeletonLoader />
      ) : (
        <>
          {post && <BlogPostRender post={post} />}
          <Footer />
        </>
      )}
      <Footer />
    </div>
  );
}
