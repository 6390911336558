//Blog Page (single)

import Footer from "../components/Footer";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import BlogPostAPI from "../apis/BlogAPI";
import BlogList from "../components/blog/BlogList";

export default function BlogHomePage() {
  const [post, setPost] = useState(null); //the most recent post to be displayed
  const [postList, setPostList] = useState([]); //the list of posts
  

  useEffect(() => {
    const blogPostAPI = new BlogPostAPI();

    blogPostAPI.getAllPublishedPosts().then((posts) => {
      setPostList(posts);
    });
    blogPostAPI
      .getMostRecentPost()
      .then((post) => {
        if (post) {
          setPost(post);
        } else {
          console.log("No posts found.");
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <Header />
      {postList && <BlogList posts={postList} />}
      <Footer />
    </div>
  );
}
