import CTAGreenBanner from "../components/cta/CTAGreenBanner";
import FAQ from "../components/information/FAQ";
import Features from "../components/information/Features";
import Footer from "../components/Footer";
import PhoneAppDisplay from "../components/displays/IOSAppDisplay";
import NotifyMeCard from "../components/cta/NotifyMeCard";
import Testimonials from "../components/userContent/Testimonials";
import WebAppDisplay from "../components/displays/WebAppDisplay";
import HeadingSection from "../components/cta/HeadingSection";
import { Link } from "react-router-dom";
import { GET_STARTED, LEARN_MORE } from "../PageRouting";

export default function HomePage() {
  return (
    <div>
      <HeadingSection />
      <PhoneAppDisplay />
      <div className="bg-teal-500">
        {/* Title */}
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">
              The SpendSage App is the easiest way to track your expenses
            </h2>
            <p className="mt-4 text-lg text-teal-50">
              No more lost receipts or missed expenses. SpendSage makes it easy
              to track your expenses and manage your budget.
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-12 text-center">
          <p className="mt-6 text-lg lg:text-xl font-semibold bg-gradient-to-r from-teal-500 to-blue-600 text-white py-2 px-4 rounded-lg shadow">
            Feel confident about your data
          </p>
          <div className="bg-white shadow rounded-lg p-6">
            <p className="text-gray-900 text-lg lg:text-xl font-semibold">
              99.62% accurate, tested across a massive data set of 11,624
              receipts.
            </p>
            <p className="mt-4 text-gray-600 text-sm lg:text-base">
              We have done extensive testing to ensure the integrity of our AI
              systems. We processed 11,624 receipts with our system and by hand.
              The AI system had an accuracy rate of 99.62%.
            </p>
          </div>

          <p className="mt-6 text-lg lg:text-xl font-semibold bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-4 rounded-lg shadow">
            Simple intuitive stats
          </p>
          <div className="bg-white shadow rounded-lg p-6">
            <p className="text-lg lg:text-xl font-semibold text-gray-900">
              See all your expenses in one place with one click.
            </p>
            <p className="mt-4 text-sm lg:text-base text-gray-600">
              With our stats page you can easily see how much you have spent
              over any period of time with our simple and intuitive user
              interface. See expenses by category and by month, fortnight, week
              and all time. Compare the previous period to the current period
              and watch for anomalies in your spending.
            </p>
          </div>

          <p className="mt-6 text-lg lg:text-xl font-semibold bg-gradient-to-r from-purple-600 to-pink-500 text-white py-2 px-4 rounded-lg shadow">
            Time is money
          </p>
          <div className="bg-white shadow rounded-lg p-6">
            <p className="text-lg lg:text-xl font-semibold text-gray-900">
              SpendSage users on average save 10.5 hours per month of time,
              managing their expenses.
            </p>
            <p className="mt-4 text-sm lg:text-base text-gray-600">
              Most Small businesses miss out on hundreds of dollars of
              expensable write offs simply because they don't track the small
              stuff. That can change today, save on your tax bill with SpendSage
              by using our simple 3 click process to save your receipts.
            </p>
          </div>
        </div>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="bg-blue-50 shadow rounded-lg p-6 mx-auto">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-extrabold text-blue-900">
                Get started now for free
              </h2>
            </div>

            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to={GET_STARTED}
                className="rounded-md bg-pink-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-300"
              >
                Join for free
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="relative isolate pt-14">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <svg
                viewBox="0 0 366 729"
                role="img"
                className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
              >
                <title>App screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width={316} height={684} rx={36} />
                  </clipPath>
                </defs>
                <path
                  fill="#4B5563"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#343E4E"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width={316}
                  height={684}
                  transform="translate(24 24)"
                  clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  <div className="overflow-hidden h-[710px] w-[320px]">
                  <img
                    src="/StatsExample.png"
                    alt=""
                    className="min-w-full min-h-full object-cover"
                  />
                </div>
                </foreignObject>
              </svg>
            </div>
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Track your expenses in real time
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Pick custom dates, compare previous weeks, and track expenses by
                categories.
              </p>
              <p className="mt-4 text-sm lg:text-base text-gray-600">
                Simple intuitive statisitcs available in the palm of your hand.
                See your total spend by category and by time period. Compare
                previous periods to current periods and watch for anomalies in
                your spending.
              </p>
              <p className="mt-4 text-sm lg:text-base text-gray-600">
                See your total category spend over any period of time, and
                specific stats about each category by selecting the category in
                the pie chart with one tap.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="relative isolate pt-14">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-500-light to-teal-200 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="py-24 sm:py-32 lg:pb-40">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Export your data to a CSV file in App.
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Keep it simple, export your data to a CSV file with one click.
                  Airdrop to your device or email it to your accountant. No more
                  trying to track down receipt images in your photo library to
                  send, simply export them as well to a zip file.
                </p>
              </div>

              <div className="max-w-xs mx-auto text-center">
                <div className="mt-16 flow-root sm:mt-24">
                  <div className="-m-2 rounded-xl mx-auto bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                    <img
                      src="/ExportPage.png"
                      alt="Export Page screenshot"
                      width={350}
                      height={800}
                      className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                    />
                  </div>
                </div>
              </div>

              <div>
                <svg
                  className="mx-auto mt-24 h-12 w-12 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 14l-7 7m0 0l-7-7m7 7V3"
                  />
                </svg>
              </div>

              <div className="mt-16 flow-root sm:mt-24">
                <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                  <img
                    src="/CSVSample.png"
                    alt="App screenshot"
                    width={2432}
                    height={1442}
                    className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-blue-500-light to-teal-200 opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
      </div>
      <CTAGreenBanner />
      <FAQ />
      <NotifyMeCard />
      <Footer />
    </div>
  );
}
